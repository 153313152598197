import { graphql, useStaticQuery } from 'gatsby';
import AwsIcon from 'svgs/single-case-study/aws.svg';
import MobileDevelopmentIcon from 'svgs/single-case-study/mobile-development.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const content = contentResolver({
    project: 'letempick',
    brandColor: '--letempick-case-study',
    brandColorGradient: {
        start: '--letempick-case-study-gradient-start',
        stop: '--letempick-case-study-gradient-stop',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.mobileDevelopment',
            Icon: MobileDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AwsIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.reactNative',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
    },
    paragraphsCounters: {
        header: 1,
        about: 3,
        challenge: 3,
        mainGoals: 3,
        solutions: 3,
        summary: 1,
    },
    caseStudies: ['nftReality', '4Tipsters', 'heyway'],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

content.headerImageAlt = 'survey software';
content.about.alt = 'audience voting app';
content.challenge.alt = 'survey software app';
content.mainGoals.alt = 'audience voting app development';
content.solutions.alt = 'survey software app development';
content.summary.alt = 'audience voting software';

export const useLetempick = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/letempick/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/letempick/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/letempick/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/letempick/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/letempick/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/letempick/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
